import { Response } from '../interfaces/response.interface';
import { GlobalsService } from '../globals.service';
import { SessionService } from '../session.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {
  loading = false
  error = ''

  constructor(private http: HttpClient, private router: Router, private globals: GlobalsService, private session: SessionService) { }

  ngOnInit() {}

  onSubmit() {
    this.loading = true;
    const data = new HttpParams()
        .set('username', this.session.username)
        .set('password', this.session.password);
  	
    this.http.post<Response>(this.globals.apiUrl + '/api/?action=login', data).subscribe(r => {
  		this.loading = false;

  		if (r.status === 'error')
  			this.error = r.message;
      else {
        this.loading = false;
        this.error = '';
        this.session.valid = true;
        this.router.navigate(['/send']);
      }
  	}, e => {
      this.loading = false;
      alert('There was an error: ' + e.message);
    });

    return false;
  }
}
