import { Response } from '../interfaces/response.interface';
import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../globals.service';
import { SessionService } from '../session.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.sass']
})

export class SendComponent implements OnInit {
	loading = false
  	error = ''
	type = 'text'
	text = ''
	image = null
	csv = null

  	constructor(private http: HttpClient, private router: Router, private globals: GlobalsService, private session: SessionService) { }

  	ngOnInit() {
  		if (!this.session.valid) {
  			this.router.navigate(['/']);
  		}
  	}

  	onSubmit() {
  		this.loading = true;

	    const formData = new FormData();
	    formData.append('username', this.session.username);
	    formData.append('password', this.session.password);
	    formData.append('type', this.type);
	    formData.append('text', this.text);

	    if (this.image)
	    	formData.append('image', this.image, this.image.name);

	    if (this.csv)
	    	formData.append('csv', this.csv, this.csv.name);

	  	this.http.post<Response>(this.globals.apiUrl + '/api/?action=send', formData).subscribe(r => {
	  		this.loading = false;

	  		if (r.status === 'error')
	  			this.error = r.message;
	      	else {
		        this.loading = false;
		        this.text = '';
		        this.error = '';
		        alert(r.message);
	      	}
	  	}, e => {
	      this.loading = false;
	      alert('There was an error: ' + e.message);
	    });

	    return false;
  	}

  	onUpload(type, event) {
		this[type] = event.target.files[0];
  	}
}
