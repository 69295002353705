import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  apiUrl = window.location.href.indexOf('localhost:4200') !== -1 ? 'http://localhost:856' : '';

  constructor() { }
}
